.Container {
    margin-top: 90px;
    z-index: 101;
    text-align: center;
}

.Text {
    display: inline-block;
    color: var(--wnwBlue);
    position: relative;
    font-size: 1.2rem;
    vertical-align: top;
    padding-top: 0.5rem;
    margin-left: 1rem;
}

.Loader,
.Loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.Loader {
    display: inline-block;
    font-size: 3px;
    position: relative;
    border-top: 1.1em solid rgba(4, 58, 85, 0.8);
    border-right: 1.1em solid rgba(4, 58, 85, 0.8);
    border-bottom: 1.1em solid rgba(4, 58, 85, 0.8);
    border-left: 1.1em solid #FFFFFF;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}